import React, { useState, useEffect } from "react";
import { Table, PageHeader, Button, message, Popconfirm } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import axios, { Axios } from "axios";
import sectionsData from "../../data/sezioni-categorie";
import { navigate } from "@reach/router";
import Loader from "../../components/loader";
import Cookies from "js-cookie";
import "../../styles/main.sass";
import "antd/dist/antd.css";
const columns = [
  {
    title: "Nome",
    dataIndex: "nome",
    key: "nome",
    render: (text) => (
      <span
        style={{ color: "grey" }}
        dangerouslySetInnerHTML={{ __html: text }}
      ></span>
    ),
  },
  {
    title: "Sezione",
    dataIndex: "sezione",
    key: "sezione",
    render: (text) => <span>{sectionsData[text].nome}</span>,
  },
  {
    title: "Categoria",
    dataIndex: "categoria",
    key: "categoria",
    render: (text) => (
      <span>{sectionsData[text[0]].categorie[text[1]].nome}</span>
    ),
  },
  {
    title: "Istituto",
    dataIndex: "istituto",
    key: "istituto",
  },
  {
    title: "Email insegnante",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Telefono insegnante",
    dataIndex: "telefonoInsegnante",
    key: "telefonoInsegnante",
  },
  {
    title: "Programma",
    dataIndex: "programma",
    key: "programma",
  },
  {
    title: "Ricevuta Versamento",
    dataIndex: 'idFolder',
    key: 'ricevuta',
    render: (value)=>(
        <a target='_blank' href={`https://concorsopaoloferro.edu.it/concorso-api/uploadFiles/${value}/versamento.pdf`}>Vedi</a>
    )
  }
];

function OnlineTable({ location }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const params = new URLSearchParams(location.search);
  const sez = params.get("sez");
  const cat = params.get("cat");

  useEffect(() => {
    if (!Cookies.get("cmac")) {
      console.log("si");
      window.location.href = "/dashboard/login";
    }

    axios
      .get(
        "https://concorsopaoloferro.edu.it/concorso-api/dashboard/all-offline-table.php"
      )
      .then((result) => {
        setLoading(false);
        console.log(result.data);
        setData(result.data);
      })
      .catch((error) => {
        setLoading(false);
        return null;
      });
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <PageHeader
        style={{
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        }}
        ghost={false}
        onBack={() => (window.location.href = "/dashboard/")}
        title="Sezioni e categorie"
      ></PageHeader>
      <Table columns={columns} dataSource={data} />
    </>
  );
}

export default OnlineTable;
